import { EyeIcon, HeartIcon } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { forwardRef } from 'react';
import { FloatingCard } from '@/components/FloatingCard';
import * as dto from '@/generated/web_api/dto';
import { useMetrics } from '@/hooks/useMetrics';
import { PUBLIC_S3_PUBLIC_PREFIX, PUBLIC_SITE_URL } from '@/utils/constants';
export const WebsiteCard = forwardRef<HTMLAnchorElement, {
  website: dto.Website;
}>(({
  website
}, ref) => {
  const imageUrl = `${PUBLIC_S3_PUBLIC_PREFIX}/website-version/${website.currentVersionId}/preview`;
  const {
    recordEvent
  } = useMetrics();
  const handleClick = async (): Promise<void> => {
    await recordEvent('website_card_click', {
      website_id: website.id,
      website_title: website.title
    });
  };
  return <Link href={`${PUBLIC_SITE_URL}/site/${website.id}`} onClick={handleClick} ref={ref}>
      <FloatingCard className="w-full cursor-pointer overflow-hidden transition-all duration-300 rounded-2xl">
        <div className="flex flex-col">
          {/* Image section */}
          <div className="relative aspect-[16/9] rounded-t-2xl overflow-hidden">
            <Image src={imageUrl} alt={website.title ?? ''} layout="fill" objectFit="cover" className="transition-transform duration-300 hover:scale-105" />
          </div>

          {/* Content section */}
          <div className="p-4 bg-white rounded-b-2xl">
            <div className="flex flex-wrap gap-2 mb-3">
              {website.tags?.slice(0, 2).map((tag, index) => <span key={index} className="bg-gray-100 text-gray-600 px-2 py-1 rounded-full text-xs font-medium">
                  {tag}
                </span>)}
            </div>
            <h3 className="text-lg font-semibold line-clamp-2 h-14 text-gray-800 text-left" style={{
            fontSize: '18px',
            // 设置字体大小为14
            lineHeight: '120%',
            // 设置行高为120%
            letterSpacing: '-2%' // 设置字间距为-2%
          }} title={website.title ?? ''}>
              {website.title ?? ''}
            </h3>
            <div className="flex items-center justify-between">
              {website.ownByUserInfo && <div className="flex items-center space-x-2">
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img src={website.ownByUserInfo?.avatarUrl ?? ''} alt={website.ownByUserInfo?.fullName ?? ''} width={44} height={44} className="rounded-full border-2 border-white shadow-sm" />
                  <span className="text-gray-600 font-medium truncate max-w-[120px]" style={{
                fontSize: '16px',
                lineHeight: '21.3px',
                letterSpacing: '0.67px'
              }}>
                    {website.ownByUserInfo?.username ?? website.ownByUserInfo?.fullName ?? ''}
                  </span>
                </div>}
              <div className="flex items-center space-x-4 text-gray-500">
                <div className="flex items-center space-x-1">
                  <HeartIcon className="w-4 h-4" />
                  <span className="font-medium" style={{
                  fontSize: '16px',
                  lineHeight: '140%',
                  letterSpacing: '0px'
                }}>
                    {website.likes || 0}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <EyeIcon className="w-4 h-4" />
                  <span className="font-medium" style={{
                  fontSize: '16px',
                  lineHeight: '140%',
                  letterSpacing: '0px'
                }}>
                    {website.views || 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FloatingCard>
    </Link>;
});
WebsiteCard.displayName = 'WebsiteCard';