'use client';

import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import DiscordIcon from '@/assets/icons/discord-round-black-icon.svg';
import EmailIcon from '@/assets/icons/email-round-black-icon.svg';
import { Background } from '@/components/Background';
import { Footer } from '@/components/Footer';
import { LightPointer } from '@/components/index/LightPointer';
import { NaviBar } from '@/components/NaviBar';
import { CreatePromptBar } from '@/components/PromptBar/CreatePromptBar';
import { Button } from '@/components/ui/button';
import JoinIcon from '@/components/ui/JoinIcon';
import { WebsiteList } from '@/components/WebsiteList/website-list';
import * as proto from '@/generated';
import { useUser } from '@/hooks/useUser';
import { useWebsiteAPI } from '@/hooks/useWebsiteAPI';
import { cn } from '@/lib/utils';
import { CREATE_INPUT_STORAGE_KEY } from '@/utils/storage';
export default function Home() {
  const router = useRouter();
  const {
    user,
    loading: userLoading,
    hasLogin
  } = useUser();
  const [prompt, setPrompt] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchDid = async () => {
      const response = await fetch('/api/auth/did');
      const data = await response.json();
      localStorage.setItem('user_did', data.did);
    };
    if (!hasLogin) {
      const storedDid = localStorage.getItem('user_did');
      if (!storedDid) {
        fetchDid();
      }
    }
  }, [hasLogin]);
  useEffect(() => {
    const input = localStorage.getItem(CREATE_INPUT_STORAGE_KEY);
    if (input && prompt === null) {
      setPrompt(input);
    }
  }, []);
  useEffect(() => {
    if (prompt === null) {
      return;
    }
    if (prompt) {
      localStorage.setItem(CREATE_INPUT_STORAGE_KEY, prompt);
    } else {
      localStorage.removeItem(CREATE_INPUT_STORAGE_KEY);
    }
  }, [prompt]);
  const {
    generate,
    error
  } = useWebsiteAPI();
  const handleSubmit = async (prompt: string, image: string | null) => {
    console.log({
      prompt,
      image
    });
    setLoading(true);
    const resp = await proto.goAdapterApi.GenWebsite({
      prompt,
      images: image ? [image] : []
    }, {
      silent: [402]
    });
    if (resp.status?.code === 402) {
      router.push('/pricing');
      return;
    }
    const websiteId = resp.websiteId;
    if (websiteId) {
      localStorage.removeItem(CREATE_INPUT_STORAGE_KEY);
      router.push(`/site/${websiteId}`);
    }
    setLoading(false);
  };
  const suggestions = ['Start a personal blog', 'Create a calculator', 'Make a snake game', 'Build a AI chatbot'];
  return <div className="flex flex-col min-h-screen relative" data-sentry-component="Home" data-sentry-source-file="page.tsx">
      <Background data-sentry-element="Background" data-sentry-source-file="page.tsx" />
      <NaviBar className="absolute w-full z-[100]" data-sentry-element="NaviBar" data-sentry-source-file="page.tsx" />
      <main className="container mx-auto px-4 lg:px-8 flex-1 relative z-10">
        <section className="min-h-screen relative flex flex-col items-center justify-center">
          <div className="flex flex-col items-center gap-4">
            <div className="text-[3rem] sm:text-[6rem] md:text-[9rem] lg:text-[12rem] leading-tight dark:text-[#bcbcbc] font-bold transition-font-size">
              CreateAny
            </div>
            <div className="text-sm sm:text-base md:text-xl text-center text-[#434343] dark:text-[#bcbcbc] font-inter font-normal break-words transition-font-size">
              <div>Create web pages, AI tools, mini-games, widgets, etc., all in one line.</div>
              <div className="hidden">Build & share & improve your ideas with the community.</div>
            </div>

            <div className="text-xs md:text-sm text-gray-500 flex items-center gap-2 md:gap-4">
              <JoinIcon Icon={DiscordIcon} href="https://discord.gg/7Frn6CGteY" title="Discord" data-sentry-element="JoinIcon" data-sentry-source-file="page.tsx" />
              <JoinIcon Icon={EmailIcon} href="mailto:hi@createany.ai" title="hi@createany.ai" data-sentry-element="JoinIcon" data-sentry-source-file="page.tsx" />
              <span className="text-xs md:text-sm font-bold dark:text-[#9e9e9e]">
                Any problem? Join our Discord or email us!
              </span>
            </div>
            <div className="max-w-full md:max-w-3xl mx-auto">
              <CreatePromptBar prompt={prompt || ''} setPrompt={setPrompt} onGenerate={handleSubmit} loading={loading} data-sentry-element="CreatePromptBar" data-sentry-source-file="page.tsx" />
              <div className="mt-6 flex flex-wrap gap-2 md:gap-3 justify-center">
                {suggestions.map((suggestion, index) => <Button key={index} variant="outline" onClick={() => setPrompt(suggestion)} className={cn('rounded-full px-3 md:px-4 py-1 md:py-2 text-xs md:text-sm font-medium transition-all bg-[#f6f6f6]/50', 'hover:(bg-[#f6f6f6]/90 text-[black] text-primary-foreground)', 'focus:ring-2 focus:ring-primary focus:ring-offset-2', 'active:scale-95')}>
                    {suggestion}
                  </Button>)}
              </div>
            </div>
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <div className="h-24 md:h-[100px] w-full" />
          </div>
          <div className="absolute bottom-6 flex flex-col items-center justify-center gap-4 md:gap-4">
            <div className="bg-gradient-to-r from-[#17002D] dark:from-[#ce92ff] to-[#8300FE] dark:to-[#8300FE] bg-clip-text text-transparent font-normal text-sm md:text-md text-center whitespace-nowrap">
              Explore more creations
            </div>
            <LightPointer color="#6C4DF8" data-sentry-element="LightPointer" data-sentry-source-file="page.tsx" />
          </div>
        </section>
        <WebsiteList className="my-10 py-10 text-center" data-sentry-element="WebsiteList" data-sentry-source-file="page.tsx" />
      </main>
      <Footer data-sentry-element="Footer" data-sentry-source-file="page.tsx" />
    </div>;
}