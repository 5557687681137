import Link from 'next/link';
import React from 'react';
import LogoIconURL from '@/assets/icons/logo-back-light.svg?url';
import { TokenBar } from '@/components/token/TokenBar';
import { UserBar } from '@/components/user/UserAvatar';
import { cn } from '@/lib/utils';
export const NaviBar: React.FC<{
  className?: string;
}> = ({
  className
}) => {
  return <header className={cn('flex justify-between items-center p-3', className)} data-sentry-component="NaviBar" data-sentry-source-file="index.tsx">
      <div className="flex items-center gap-8">
        <Link href="/" className="flex items-center" data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <img className="w-14 h-14" src={LogoIconURL.src} />
          <span className="font-normal text-xl hidden md:block ml-2">CreateAny</span>
        </Link>

        <nav className="pl-6 hidden md:flex items-center gap-10">
          <Link href="/pricing" className="text-gray-600 hover:text-gray-900 font-medium transition-colors" data-sentry-element="Link" data-sentry-source-file="index.tsx">
            Pricing
          </Link>
        </nav>
      </div>

      <div className="flex items-center gap-8">
        <TokenBar data-sentry-element="TokenBar" data-sentry-source-file="index.tsx" />

        <UserBar enableGetStarted data-sentry-element="UserBar" data-sentry-source-file="index.tsx" />
      </div>
    </header>;
};